const myersBriggsArray = [
    {
    id: 1,
    type: 'ISTJ',
    description: 'I am quiet, serious, earn success by thoroughness and dependability. Practical, matter-of-fact, realistic, and responsible. Decide logically what should be done and work toward it steadily, regardless of distractions. Take pleasure in making everything orderly and organized - my work, my home, my life. Value traditions and loyalty.',
    connector: 'So God and I both like to be the Provider!',
    attribute: 'Providing',
    },
    {
    id: 2,
    type: 'ISTP',
    description: 'I am tolerant and flexible, a quiet observer until a problem appears, then act quickly to find workable solutions. Analyze what makes things work and readily get through large amounts of data to isolate the core of practical problems. Interested in cause and effect, organize facts using logical principles, value efficiency.',
    connector: 'So God and I both like to be the Teacher!',
    attribute: 'Teaching',
    },
    {
    id: 3,
    type: 'ISFJ',
    description: 'I am quiet, friendly, responsible, and conscientious. Committed and steady in meeting my obligations. Thorough, painstaking, and accurate. Loyal, considerate, notice and remember specifics about people who are important to me, concerned with how others feel. Strive to create an orderly and harmonious environment at work and at home.',
    connector: 'So God and I both like to be Faithful!',
    attribute: 'Faithful',
    },
    {
    id: 4,
    type: 'ISFP',
    description: 'I am quiet, friendly, sensitive, and kind. Enjoy the present moment and what is going on around me. Like to have my own space and to work within my own time frame. Loyal and committed to my values and to people who are important to me. Dislike disagreements and conflicts, do not force my opinions or values on others.',
    connector: 'So God and I both like to be Humble!',
    attribute: 'Humble',
    },
    {
    id: 5,
    type: 'INTJ',
    description: 'I have an original mind and great drive for implementing my ideas and achieving my goals. Quickly see patterns in external events and develop long-range explanatory perspectives. When committed, organize a job and carry it through. Skeptical and independent, have high standards of competence and performance - for myself and others.',
    connector: 'So God and I both like to be Creative!',
    attribute: 'Creative',
    },
    {
    id: 6,
    type: 'INTP',
    description: 'I seek to develop logical explanations for everything that interests me. Theoretical and abstract, interested more in ideas than in social interaction. Quiet, contained, flexible, and adaptable. Have unusual ability to focus in depth to solve problems in my area of interest. Skeptical, sometimes critical, always analytical.',
    connector: 'So God and I both like to be the Teacher!',
    attribute: 'Teaching',
    },
    {
    id: 7,
    type: 'ESTJ',
    description: 'I am practical, realistic, matter-of-fact. Decisive, quickly move to implement decisions. Organize projects and people to get things done, focus on getting results in the most efficient way possible. Take care of routine details. Have a clear set of logical standards, systematically follow them and want others to also. Forceful in implementing my plans.',
    connector: 'So God and I both like to be the Provider!',
    attribute: 'Providing',
    },
    {
    id: 8,
    type: 'ESTP',
    description: 'I am flexible and tolerant, taking a pragmatic approach focused on immediate results. Theories and conceptual explanations bore me - I want to act energetically to solve the problem. Focus on the here-and-now, spontaneous, enjoy each moment that I can be active with others. Enjoy material comforts and style. Learn best through doing.',
    connector: 'So God and I both like to be Wise!',
    attribute: 'Wise',
    },
    {
    id: 9,
    type: 'ESFJ',
    description: 'I am warmhearted, conscientious, and cooperative. Want harmony in my environment, work with determination to establish it. Like to work with others to complete tasks accurately and on time. Loyal, follow through even in small matters. Notice what others need in their day-by-day lives and try to provide it. Want to be appreciated for who I am and for what I contribute.',
    connector: 'So God and I both like to be the Peacemaker!',
    attribute: 'Peaceful',
    },
    {
    id: 10,
    type: 'ESFP',
    description: 'I am outgoing, friendly, and accepting. Exuberant lover of life, people, and material comforts. Enjoy working with others to make things happen. Bring common sense and a realistic approach to my work, and make work fun. Flexible and spontaneous, adapt readily to new people and environments. Learn best by trying a new skill with other people.',
    connector: 'So God and I both like to be Gracious!',
    attribute: 'Gracious',
    },
    {
    id: 11,
    type: 'ENTJ',
    description: 'I am frank, decisive, assume leadership readily. Quickly see illogical and inefficient procedures and policies, develop and implement comprehensive systems to solve organizational problems. Enjoy long-term planning and goal setting. Usually well informed, well read, enjoy expanding my knowledge and passing it on to others. Forceful in presenting my ideas.',
    connector: 'So God and I both like to be the Planner!',
    attribute: 'Planning',
    },
    {
    id: 12,
    type: 'ENTP',
    description: 'I am quick, ingenious, stimulating, alert, and outspoken. Resourceful in solving new and challenging problems. Adept at generating conceptual possibilities and then analyzing them strategically. Good at reading other people. Bored by routine, will seldom do the same thing the same way, apt to turn to one new interest after another.',
    connector: 'So God and I both like to be Omniscient!',
    attribute: 'Omniscient',
    },
    {
    id: 13,
    type: 'INFJ',
    description: 'I seek meaning and connection in ideas, relationships, and material possessions. Want to understand what motivates people and am insightful about others. Conscientious and committed to my firm values. Develop a clear vision about how best to serve the common good. Organized and decisive in implementing my vision.',
    connector: 'So God and I both like to be Loving!',
    attribute: 'Loving',
    },
    {
    id: 14,
    type: 'INFP',
    description: 'I am idealistic, loyal to my values and to people who are important to me. Want an external life that is congruent with my values. Curious, quick to see possibilities, can be catalysts for implementing ideas. Seek to understand people and to help me fulfill my potential. Adaptable, flexible, and accepting unless a value is threatened.',
    connector: 'So God and I both like to be Faithful!',
    attribute: 'Faithful',
    },
    {
    id: 15,
    type: 'ENFJ',
    description: 'I am warm, empathetic, responsive, and responsible. Highly attuned to the emotions, needs, and motivations of others. Find potential in everyone, want to help others fulfill their potential. May act as catalysts for individual and group growth. Loyal, responsive to praise and criticism. Sociable, facilitate others in a group, and provide inspiring leadership.',
    connector: 'So God and I both like to be the one who Relates to everyone!',
    attribute: 'Relational',
    },
    {
    id: 16,
    type: 'ENFP',
    description: 'I am warmly enthusiastic and imaginative. See life as full of possibilities. Make connections between events and information very quickly, and confidently proceed based on the patterns I see. Want a lot of affirmation from others, and readily give appreciation and support. Spontaneous and flexible, often rely on my ability to improvise and my verbal fluency.',
    connector: 'So God and I both like to be the one who Relates to everyone!',
    attribute: 'Relational',
    }
]

export default myersBriggsArray;